import { ExtraAbstractModel } from '../../../shared/models/extra-abstract.model';

export class CampaignsModel extends ExtraAbstractModel {
  discountType: number;
  endDate: string;
  id: number;
  isCompleted: boolean;
  name: string;
  startDate: string;
  status: number;
  value: number;
  valueType: number;
  campaignVisual: number;
  discountValue: number;

  constructor(entity: any = {}) {
    super(entity);
  }
}

export enum CampaignType {
  AllProduct = 1,
  PerProduct
}

export enum CampaignSaleItem {
  Default,
  PerProduct = 1,
  PerCart
}
export enum CampaignVisual {
  Show = 1,
  Hide
}

export enum DiscountType {
  Default,
  VisaDiscount,
  MasterCard,
  VisaDigitalCard,
  Installment,
  CashOnHand,
  PlusPoints,
  Balance,
  IPay,
  Amex,
  PartByPart,
  ApplePay,
  GooglePay,
  TBCPay
}
export enum CampaignValueType {
  Default,
  Amount = 1,
  Percent
}

export enum CampaignStatus {
  Active = 1,
  Cancelled
}

export enum CampaignChannel {
  All = 1,
  Web = 2,
  App = 3
}
